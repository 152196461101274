import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {PromotionModel} from "../models/promotion.model";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /*this is to read all from environments, so we don't have to import environments in components and services
  * missing isAffiliated: boolean */

  assetsPath:string;

  isRivalCasino: boolean;
  name = environment.rivalName; //sortName, what rival uses in the api url (normally we ue it for the assetsPath)  publicPromotions: PromotionModel[];
  casinoId = environment.casinoId;

  apiUrl: string;

  brandName = environment.prettyName;
  contactEmail = environment.contactEmail;
  docsEmail = environment.docsEmail;
  GA_TRACKING = environment.GA_TRACKING;

  publicPromotions: PromotionModel[] = environment.publicPromotions;

  chatSystem: string;
  rivalChatGroupName: string | undefined;
  tawk_apiKey: string | undefined;
  tawk_propId: string | undefined;
  tawk_widgetId: string | undefined;

  //SEO:
  metaDesc:string = environment.metaDescription;
  logoName:string = environment.logoName;
  logoAltText: string = environment.logoAltText;

  //licence:
  licenceNo = environment.licenseNo;
  licenseInfo?: { pdb_name: string, pdb_address: string, lh_name: string, lh_address: string } = environment.license;
  withdrawalTime = environment.legalData.withdrawalTime;
  isAffTermsNeeded: boolean = environment.legalData.affTerms;


  games = environment.games;
  affiliatedGames: {name: string, regex: RegExp}[] = environment.affiliatedGames;


  hasMinimalRegistration: boolean = environment.hasMinReg;


  constructor() {
    this.isRivalCasino = environment.platform === 'rival';
    this.assetsPath = environment.assetsPath ?? `assets/brands/${this.name}`

    // this.apiUrl = environment.apiUrl ?? `https://demo.casinocontroller.com/${this.name}/api-player/v3/jsonrpc` //for demo mode
    this.apiUrl = environment.apiUrl ?? `https://www.casinocontroller.com/${this.name}/api-player/v3/jsonrpc` //for prod & publishing

    this.publicPromotions = environment.publicPromotions;

    this.chatSystem = this.isRivalCasino ? 'rival' : environment.chatSystem;
    this.rivalChatGroupName = this.isRivalCasino ? environment.rivalChatGroupName : undefined;
    if (this.chatSystem === 'tawk'){
      this.tawk_apiKey = environment.tawk_apiKey;
      this.tawk_propId = environment.tawk_propertyId;
      this.tawk_widgetId = environment.tawk_widgetId;
    }

  }



}
